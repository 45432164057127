import React from "react"
import ContentArtistVideoDetails from "../components/ContentArtistVideoDetails"
import queryString from "query-string"

const Videos = ({ location }) => {
  const queryParams =
    location && location.search ? queryString.parse(location.search) : {}
  return (
    <React.Fragment>
      <ContentArtistVideoDetails
        artistID={queryParams.__aid}
        artistName={queryParams.a}
        videoID={queryParams.__vid}
      />
    </React.Fragment>
  )
}

export default Videos

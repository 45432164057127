import React, { useContext } from "react"
import {
  MEDIA_TYPE_VIDEO,
  convertToSlug,
  VIDEO_MODE_MAXIMIZED,
  DATA_TYPE_ARTIST_VIDEO,
} from "../../../constants/props"
import { artistVideoDetailsShareURL } from "../../../constants/share"
import LikeAndShare from "../../LikeAndShare"
import StyledImage from "../../StyledImage"
import moment from "moment-timezone"
import styled from "styled-components"
import * as St from "../../Styled"
import { AppContext } from "../../../contexts/AppContext"
import useRecordUserData from "../../../hooks/useRecordUserData"

const LikeShare = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: row;
  vertical-align: middle;
  flex: 1;
  position: relative;
`

const VideoCard = ({ video, artist }) => {
  const basePrefix = `${process.env.STRAPI_SERVER_URL}`
  const { selectedCountry, updatePlayer, userState, ctxPlayer } = useContext(
    AppContext
  )
  const recordUserData = useRecordUserData({
    type: DATA_TYPE_ARTIST_VIDEO,
    id: video ? video.id : null,
    user: userState ? userState.id : null,
    viewed: video.viewed != null ? video.viewed : 0,
  })

  if (!video) return null
  const { id, uri, title, updatedAt, premium } = video
  const image =
    video.image && video.image.url
      ? video.image.url.startsWith("http")
        ? video.image.url
        : basePrefix + video.image.url
      : ""
  const artistNameSlug = artist && artist.slug ? artist.slug : "all"
  const artistID = artist && artist.id ? artist.id : "all"

  const isPlaying =
    ctxPlayer.id === id &&
    ctxPlayer.dataType === DATA_TYPE_ARTIST_VIDEO &&
    ctxPlayer.playing
      ? true
      : false

  return (
    <St.VideoGridWrapper>
      <St.VideoGridImageWrapper>
        <StyledImage
          image={image}
          width={"100%"}
          height={"100%"}
          borderRadius={"0px"}
          playing={isPlaying}
          premium={premium}
          imageAlt={title}
          onClickPlay={event => {
            event.preventDefault()
            event.stopPropagation()

            if (!isPlaying) {
              updatePlayer({
                type: MEDIA_TYPE_VIDEO,
                id,
                title,
                uri,
                preview:
                  video.image && video.image.url
                    ? video.image.url.startsWith("http")
                      ? video.image.url
                      : basePrefix + video.image.url
                    : "",
                playlist: [],
                station: null, // artist is owner of this video (and not station)
                artist: artist,
                videoMode: VIDEO_MODE_MAXIMIZED,
                subtitles: video.subtitles,
                about: video.about,
                viewed: video.viewed != null ? video.viewed : 0,
                dataType: DATA_TYPE_ARTIST_VIDEO,
                playing: true,
              })

              if (userState && userState.isLoggedIn) {
                recordUserData()
              }
            } else {
              updatePlayer({
                ...ctxPlayer,
                playing: false,
              })
            }

            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
          }}
        />{" "}
      </St.VideoGridImageWrapper>
      <St.VideoGridInfoWrapper>
        <St.VideoGridTitle> {title} </St.VideoGridTitle>
        <St.VideoGridSingleLineText>{video.about}</St.VideoGridSingleLineText>
        {video.station && (
          <St.VideoGridSingleLineText>
            {video.station.title}
            <span> • </span>
            <span>{moment(updatedAt).format("DD MMMM YYYY")}</span>
          </St.VideoGridSingleLineText>
        )}
        <LikeShare>
          <LikeAndShare
            shareURL={
              selectedCountry && selectedCountry.id
                ? artistVideoDetailsShareURL(
                    id,
                    convertToSlug(title),
                    artistID,
                    artistNameSlug,
                    image
                  )
                : null
            }
            id={id}
            likeType={DATA_TYPE_ARTIST_VIDEO}
            shareID={"selectedArtistVideo_" + id}
          />
        </LikeShare>
      </St.VideoGridInfoWrapper>
    </St.VideoGridWrapper>
  )
}

export default VideoCard

import React, { useState } from "react"
import * as St from "../../Styled"
import VideoCard from "./VideoCard"

const AllVideos = ({ artist }) => {

  let [sortCriteria, setSortCriteria] = useState("asc")

  const onSortChange = event => {
    setSortCriteria(event.target.value)
  }

  let videos = []

  if (artist && artist.videos && artist.videos.length > 0) {
    // here we are assuming that we get videos sorted by their titles already in ascending order from above
    if (sortCriteria === "asc") {
      videos = artist.videos
    } else {
      videos = artist.videos.reverse()
    }
  }

  return (
    <St.AllCardsSection>
      <St.AllCardsTopSection>
        <St.AllCardsHeading>
          VIDEO ON DEMAND : {artist.title}
        </St.AllCardsHeading>
        <St.SortSpan>
          SORT :
          <St.SortSelect onChange={onSortChange}>
            <St.SortOption
              value="title:asc"
              selected={sortCriteria === "asc" ? true : false}
            >
              A-Z
            </St.SortOption>
            <St.SortOption
              value="title:desc"
              selected={sortCriteria === "desc" ? true : false}
            >
              Z-A
            </St.SortOption>
          </St.SortSelect>
        </St.SortSpan>
      </St.AllCardsTopSection>
      <St.VideoGridAllWrapper>
        {videos &&
          videos.length &&
          videos.map((video, i) => (
            <VideoCard key={i} video={video} artist={artist} />
          ))}
      </St.VideoGridAllWrapper>
    </St.AllCardsSection>
  )
}

export default AllVideos
